import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Envelope, Telephone, GeoAlt, CCircle } from "react-bootstrap-icons";

import "./App.css";

function App() {
  return (
    <React.Fragment>
      <div className="header">
        <img
          src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/Atoer-TEXT-white-smaller%20(1)_OTRk3Njh_.png?updatedAt=1722761532363"
          alt="atoer-text"
          className="logo"
        ></img>
        <Row>
          <Col>
            <br></br>
            <br></br>
            <br></br>
            <h1 style={{ fontSize: "60px", color: "white" }}>
              <b>EARNED WAGE ACCESS</b>
            </h1>
            <h3 style={{ color: "white" }}>
              Solusi kasbon karyawan dalam satu aplikasi
            </h3>
            <br></br>
            <a href="#footer" alt="contact-us" className="link-button">
              Hubungi Kami
            </a>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <br></br>
            <br></br>
            <img
              src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/bannerphoto%20(1)_MyEIFHsOR.png?updatedAt=1722761532778"
              alt="atoer"
              className="banner"
            ></img>
          </Col>
        </Row>
      </div>
      <div className="intro">
        <Row>
          <Col style={{ textAlign: "center" }}>
            <img
              src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/websitepic01_Z2EjLBacs.png?updatedAt=1722761532834"
              alt="intro-img"
              className="intro-img"
            ></img>
          </Col>
          <Col>
            <h1 style={{ color: "#5f7af7" }} className="intro-text">
              <b>Fasilitas Akses Gaji Karyawan Setiap Saat</b>
            </h1>
            <p>
              <b>Akses Gaji yang Diperoleh (Earned Wage Access)</b>
            </p>
            <p>
              Karyawan bisa mengakses sebagian dari gaji mereka sebelum tanggal
              pembayaran gaji resmi. Dengan sistem ini, karyawan bisa menarik
              sejumlah uang yang telah mereka peroleh sepanjang bulan kerja.
            </p>
          </Col>
        </Row>
      </div>
      <br></br>
      <div className="info">
        <h1
          className="info-title"
          style={{ color: "#5f7af7", textAlign: "center" }}
        >
          <b>Dana Darurat dengan Ambil Gaji Awal</b>
        </h1>
        <h5 style={{ textAlign: "center" }}>
          Menghindari tekanan keuangan karyawan dengan akses gaji diawal tanpa
          mengganggu kas kantor.
        </h5>
        <br></br>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <div className="info-column">
              <Row>
                <Col xs={2} style={{ textAlign: "center" }}>
                  <img
                    src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/icon%20penarikan_drbW7WKgy.png?updatedAt=1722841760899"
                    alt="icon-disburse"
                    className="info-column-disburse-icon"
                  ></img>
                </Col>
                <Col style={{ textAlign: "left" }}>
                  <h4>
                    <b>Akses Gaji</b>
                  </h4>
                  <p>
                    Kami memberikan akses agar karyawan bisa mengambil gaji
                    mereka sendiri tanpa menunggu tanggal gajian kapan saja
                    dimana saja.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <div className="info-column">
              <Row>
                <Col xs={2} style={{ textAlign: "center" }}>
                  <img
                    src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/icon%20tagihan_CQkomlc1P.png?updatedAt=1722841761012"
                    alt="icon-bill"
                    className="info-column-bill-icon"
                  ></img>
                </Col>
                <Col style={{ textAlign: "left" }}>
                  <h4>
                    <b>Tagihan & Top-up</b>
                  </h4>
                  <p>
                    Semakin mudah bayar berbagai macam produk top-up maupun
                    pembayaran tagihan dengan proses yang aman dan praktis
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="benefit">
        <Row className="benefit-row">
          <Col>
            <h2 className="benefit-title">
              <b>Keuntungan Atoer Untuk Karyawan</b>
            </h2>
            <br></br>
            <ul style={{ padding: 0 }}>
              <li>
                <h4>
                  <b>Tingkatkan Retensi Karyawan</b>
                </h4>
                <p>
                  Sehat finansial, karyawan lebih produktif dan bekerja lebih
                  lama di perusahaan
                </p>
              </li>
              <li>
                <h4>
                  <b>Cash-Flow Perusahaan tidak terganggu</b>
                </h4>
                <p>
                  Semua dana pengajuan Akses Gaji karyawan akan disediakan oleh
                  Atoer, sampai nanti waktu gajian
                </p>
              </li>
              <li>
                <h4>
                  <b>Biaya Rendah</b>
                </h4>
                <p>
                  Dana yang dipakai bukan pinjaman sehingga tidak berbunga dan
                  biaya admin rendah
                </p>
              </li>
            </ul>
          </Col>
          <Col className="benefit-img-col">
            <img
              src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/websitepic02_u3t5qxCdh.png?updatedAt=1722843091973"
              alt="benefit-img"
              className="benefit-img"
            ></img>
          </Col>
        </Row>
      </div>
      <div id="footer" className="footer">
        <Row>
          <Col>
            <img
              src="https://ik.imagekit.io/44d0h1g5ht2/atoerhr-img/Atoer%20logo%20text_2OqRBKzPa.png?updatedAt=1723537682929"
              alt="atoer-text"
              className="logo-footer"
            ></img>
            <br></br>
            <br></br>
            <p style={{ paddingRight: "20px" }}>
              PT Ambil Gaji Duluan juga telah memiliki Tanda Daftar
              Penyelenggara Sistem Elektronik{" "}
              <b>(PSE) No. 013817.01/DJAI.PSE/05/2024</b>
            </p>
            <br></br>
          </Col>
          <Col className="footer-col">
            <h2>Kontak</h2>
            <br></br>
            <Row>
              <Col xs={1} style={{ margin: "0 10px" }}>
                <Envelope size={25} />
              </Col>
              <Col>
                <p>info@atoer.id</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ margin: "0 10px" }}>
                <Telephone size={25} />
              </Col>
              <Col>
                <p>0823-1826-7523</p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} style={{ margin: "0 10px" }}>
                <GeoAlt size={25} />
              </Col>
              <Col>
                <p>
                  PT. Ambil Gaji Duluan<br></br> Jl. Hayam Wuruk No. 33, Kel.
                  Kebon kelapa, Kec. Gambir, Jakarta Pusat
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ backgroundColor: "#f4f5f8", textAlign: "center" }}>
        <p style={{ margin: 0, paddingBottom: "20px", paddingTop: "20px" }}>
          <CCircle /> 2024 atoer.id All Rights Reserved. Jakarta Pusat
        </p>
      </div>
    </React.Fragment>
  );
}

export default App;
